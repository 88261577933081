.headerContainer{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: #0D5C63;
}

.headerColumn{
    align-items: center;
    align-content: center;
    display: flex; 
    align-items: center;
    font-size: medium;
    color: white;
}

.headerColumnCenter{
    justify-content: center;
}