.mainContainer{
    background-color: white;
    border-radius: 30px;
    margin-bottom: 10px;
}

.mainDataContainer{
    display: flex;
    flex-direction: row;
}

.expandPart{
    width: 3%; 
    display: flex; 
    align-items: center;
}

.dataColumn{
    text-align: left; 
    display: flex; 
    align-items: center;
    font-size: small;
}

.dataColumnCenter{
    justify-content: center;
}