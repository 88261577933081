.container{
    margin-top: 10px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 10px;
}

.titleContainer{
    width: 600px;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-style: solid;
    border-width: 0px;
    background-color: rgb(13, 92, 99, 0.7);
    color: #FFFFFF;
}
.divTitle{
    display: flex;
    align-items: center;
}

.divTampon{
    flex-grow: 1;
}

.noContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.addButton{
    margin-right: 10px;
}

.dataDetailContainer{
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dataDetailContainerCenterAlign{
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.tableData{
    width: 100%;
}

.tableData thead th{
    text-align: center;
}
.tableData tbody tr td{
    text-align: center;
}