.detailContainer{
    display: flex;
    flex-direction: row;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: lightgrey;
}

.accountContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
}
.referentContainer{
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
}

.detailSpinnerLoader{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}