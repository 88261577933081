.dialogcontainer {
    margin: 5px;
    padding: 10px;
    width: 50vh;
    border: #0D5C63;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
}

.dialogTitle{
    color: #0D5C63;
}

.textFieldStyle {
    width: 100%;
    & .MuiOutlinedInput-root {
        margin-bottom: 20px;
    }
}

.textFieldStyle .root {}

.error {
    width: 90%;
    text-align: center;
    margin-top: 40px;
    color: red;
    background-color: #0D5C63
}

.autoCompleteStyle {
    width: 100%;
    margin-right: auto;
    padding-bottom: 0;
    font-weight: 500;
}