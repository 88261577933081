
.drop_zone {
    border: dashed 2px #FFFFFF;
  }
.drop_zone span {
  color: white;
}
.drop_zone label {
  color: white;
  border: dashed 2px #FFFFFF;
}
.drop_zone svg path {
  fill: white;
  border: dashed 2px #FFFFFF;
}